.how-it-works-container{
    background-color: #e8ecee;
    display: flex;
    flex: 4 1;
}

.how-it-works-title-text{
    color: black;
    font-size: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    padding-top: 32px;
    text-align: center;
}

.how-it-works-text{
    color: black;
    font-size: 1rem;
    display: flex;
    text-align: left;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 32px;
    padding-bottom: 32px;
}

.flex-columns{
    background-color: lightgrey;
}

.image-row{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.logo-image{
    max-height: 150px;
    height: auto;
    max-width: auto;
}

.company-col{
    font-size: 3rem;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    color: black;
}

.about-link{
    color: black;
    font-size: 2rem;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}

.social-link{
    color: #319BE9;
    font-size: 50px;
}

@media screen and (max-width: 1024px) {
    div.how-it-works-title-text {
      font-size: 3rem;
    }
    div.how-it-works-text {
        font-size: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .flex-columns{
        display: none;
    }
    div.how-it-works-title-text {
        font-size: 2rem;
    }
    div.how-it-works-text {
        font-size: 1rem;
    }
}

@media screen and (max-width: 435px) {
    .flex-columns{
        display: none;
    }
    div.how-it-works-title-text {
        font-size: 1.5rem;
    }
    div.how-it-works-text {
        font-size: 0.9rem;
    }
}
