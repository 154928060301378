.section-one-container{
    background-color: 'white';
}

.section-one-title-text{
    color: black;
    font-size: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    padding-top: 32px;
    text-align: center;
}

.section-one-text{
    color: black;
    font-size: 1.5rem;
    display: flex;
    text-align: center;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 32px;
    padding-bottom: 32px;
}

@media screen and (max-width: 1024px) {
    div.section-one-title-text {
      font-size: 3rem;
    }
    div.section-one-text {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 768px) {
    div.section-one-title-text {
        font-size: 2rem;
    }
    div.section-one-text {
        font-size: 1rem;
    }
}

@media screen and (max-width: 435px) {
    div.section-one-title-text {
        font-size: 1.5rem;
    }
    div.section-one-text {
        font-size: 1rem;
    }
}
