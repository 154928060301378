.App-header {
  background-color: #555555;
  z-index: 9999;
}
  
.sticky {
  position: sticky;
  top: 0;
  width: 100%
}
  
.MuiSvgIcon-root {
  color: white;
}

@media screen and (max-width: 280px) {
  .row {
    max-width: 280px;
  } 
}