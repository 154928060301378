.title-container{
    background-image: url("../Images/assessor-two.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-container:before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
}

.title-row {
    min-height: 100vh;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.title-text{
    color: white;
    font-size: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    text-align: center;
}

.title-sub-text{
    color: white;
    font-size: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    text-align: center;
}

@media screen and (max-width: 1024px) {
    div.title-row {
        min-height: 100vh;
    }
    div.title-text {
      font-size: 3rem;
    }
    div.title-sub-text {
        font-size: 2rem;
    }
}

@media screen and (max-width: 768px) {
    div.title-row {
        min-height: 100vh;
    }
    div.title-text {
      font-size: 2rem;
    }
    div.title-sub-text {
        font-size: 1rem;
    }
}

@media screen and (max-width: 425px) {
    div.title-row {
        min-height: 100vh;
    }
    div.title-text {
      font-size: 1.5rem;
    }
    div.title-sub-text {
        font-size: 1rem;
    }
}