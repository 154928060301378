.about-container{
    height: 100%;
    display: flex;
    flex: 4 1;
    background-color: white;
}

.about-title-text{
    color: black;
    font-size: 3rem;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 16px;
    padding-bottom: 16px;
}

.about-text{
    color: black;
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    padding-left: 5%;
    padding-right: 5%;
}

.stephen-paragraph{
    color: black;
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    padding-left: 5%;
    padding-right: 5%;
}

.flex-columns{
    background-color: lightgrey;
}

@media screen and (max-width: 1024px) {
    div.about-title-text {
      font-size: 3rem;
    }
    div.about-text {
        font-size: 1rem;
    }
}
  
@media screen and (max-width: 768px) {
    .flex-columns{
        display: none;
    }
    .about-text{
        padding-left: 10%;
        padding-right: 10%;
    }
    .about-title-text{
        color: black;
        font-size: 2rem;
        padding-left: 10%;
        padding-right: 10%;
    }
    .stephen-paragraph{
        padding-left: 10%;
        padding-right: 10%;
    }
}
  
@media screen and (max-width: 425px) {
    .about-text{
        padding-left: 10%;
        padding-right: 10%;
        font-size: 0.9rem;
    }
    .stephen-paragraph{
        padding-left: 10%;
        padding-right: 10%;
        font-size: 0.9rem;
    }
    .about-title-text{
        color: black;
        font-size: 1.5rem;
        padding-left: 10%;
        padding-right: 10%;
    }
}

@media screen and (min-height: 1366px) {
    .about-container{
        height: 100vh;
    }
}