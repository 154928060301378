.section-three-container{
    background-color: lightgrey;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 16px;
    padding-bottom: 16px;
}

.section-three-title-text{
    color: black;
    font-size: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    text-align: center;
}

.section-three-text{
    color: black;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 32px;
    padding-bottom: 32px;
    text-align: center;
}

@media screen and (max-width: 1024px) {
    div.section-three-title-text {
      font-size: 3rem;
    }
    div.section-three-text {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 768px) {
    div.section-three-title-text {
        font-size: 2rem;
    }
    div.section-three-text {
        font-size: 1rem;
    }
}

@media screen and (max-width: 425px) {
    div.section-three-title-text {
        font-size: 1.5rem;
    }
    div.section-three-text {
        font-size: 1rem;
    }
}