.section-two-container{
    background-color: #319BE9;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 32px;
    padding-bottom: 32px;
}

.section-two-col{
    padding-top: 16px;
    padding-bottom: 16px;
}

.section-two-row{
    justify-content: center;
}

.section-two-title-text{
    color: white;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 16px;
    padding-bottom: 16px;
    justify-content: center;
    text-align: center;
}

.section-two-text{
    color: white;
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
    justify-content: center;
}

@media screen and (max-width: 1210px) {
    div.section-two-col {
      min-width: 400px;
    }
}

@media screen and (max-width: 1024px) {
    div.section-two-col {
      min-width: 400px;
    }
}

@media screen and (max-width: 768px) {
    div.section-two-col {
        min-width: 400px;
    }
}

@media screen and (max-width: 435px) {
    div.section-two-col {
        min-width: 280px;
    }
}

@media screen and (max-width: 280px) {
    div.section-two-col {
        min-width: 280px;
    }
}