.section-four-container{
    background-color: 'white';
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 32px;
    padding-bottom: 32px;
}

.section-four-col{
    min-width: 200px;
    padding-top: 16px;
    padding-bottom: 16px;
    align-items: center;
}

.section-four-row{
    justify-content: center;
}

.section-four-title-text{
    color: black;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 16px;
    padding-bottom: 16px;
    justify-content: center;
    text-align: center;
}

.section-four-text{
    color: black;
    font-size: 1.5rem;
    display: flex;
    justify-content: left;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 16px;
    text-align: center;
}

 .carousel,.item,.active{
    height:100%;
    width: 100%;
  }
 .carousel-inner{
     height:100%;
     width: 100%;
 }

 @media screen and (max-width: 1024px) {
    div.section-four-title-text {
      font-size: 1.5rem;
    }
    div.section-four-text {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 768px) {
    div.section-four-title-text {
        font-size: 1.5rem;
    }
    div.section-four-text {
        font-size: 1rem;
    }
}

@media screen and (max-width: 425px) {
    div.section-four-title-text {
        font-size: 1.25rem;
    }
    div.section-four-text {
        font-size: 1rem;
    }
}